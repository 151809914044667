<template>
    <div>
        <div class="l-inline l-center-v l-gap-2 filter-actions">
            <i18n path="showOf">
                <b>{{ filteredTrackers.length }}</b>
                <b>{{ trackers.length }}</b>
            </i18n>

            <a v-if="isClearFilterVisible" @click="$emit('clearFilter')">
                {{ $t('clearFilter') }}
            </a>

            <icon-button :title="$t('tooltipDownload')" @click="handleDownload">
                <file-download-icon width="18" height="18" />
            </icon-button>
        </div>

        <table
            v-infinite-scroll="loadMoreTrackers"
            infinite-scroll-distance="25"
        >
            <thead>
                <v-draggable
                    v-model="dynamicColumns"
                    tag="tr"
                    draggable=".dynamic"
                >
                    <template #header>
                        <th class="checkbox-cell">
                            <label>
                                <input type="checkbox" @change="selectAll" />
                            </label>
                        </th>

                        <th>{{ $t('columns.icon') }}</th>

                        <th>
                            {{ $t('columns.name') }}

                            <icon-button @click="handleSort('name')">
                                <sort-arrow-icon
                                    :direction="
                                        sortBy === 'name' ? sortDirection : 0
                                    "
                                    width="10"
                                    height="10"
                                />
                            </icon-button>
                        </th>

                        <th>
                            {{ $t('columns.type') }}

                            <icon-button @click="handleSort('type')">
                                <sort-arrow-icon
                                    :direction="
                                        sortBy === 'type' ? sortDirection : 0
                                    "
                                    width="10"
                                    height="10"
                                />
                            </icon-button>
                        </th>
                    </template>

                    <th
                        v-for="column in dynamicColumns"
                        :key="column"
                        class="dynamic"
                    >
                        {{ getDynamicColumnTitle(column) }}

                        <icon-button @click="handleSort(column)">
                            <sort-arrow-icon
                                :direction="
                                    sortBy === column ? sortDirection : 0
                                "
                                width="10"
                                height="10"
                            />
                        </icon-button>

                        <icon-button @click="handleColumnRemove(column)">
                            <remove-icon width="10" height="10" />
                        </icon-button>
                    </th>

                    <template #footer>
                        <th
                            v-if="dynamicColumnOptionsRemaining.length"
                            class="action"
                        >
                            <base-multiselect
                                :options="dynamicColumnOptionsRemaining"
                                :taggable="isStaff"
                                :placeholder="$t('addColumnPlaceholder')"
                                track-by="key"
                                label="label"
                                group-label="group"
                                group-values="items"
                                group-select
                                @select="handleColumnAdd"
                                @tag="handleColumnAdd"
                            />
                        </th>
                    </template>
                </v-draggable>
            </thead>

            <tr
                v-for="tracker in trackersPortion"
                :key="tracker.id"
                @click="
                    $emit('click', tracker.asset)
                    selected = [tracker.asset]
                "
            >
                <td class="checkbox-cell">
                    <label @click.stop>
                        <input
                            v-model="selected"
                            type="checkbox"
                            :value="tracker.asset"
                            @click.stop
                        />
                    </label>
                </td>

                <td>
                    <asset-avatar :tracker="tracker" :size="32" linkable />
                </td>

                <td>
                    {{ tracker.asset_details.name }}
                </td>

                <td>
                    {{ getTrackerTypeTranslated(tracker) }}
                </td>

                <td v-for="column in dynamicColumns" :key="column">
                    <template v-if="column === 'last_contact'">
                        <time-ago
                            v-if="tracker.last_message_timestamp"
                            :from-datetime="tracker.last_message_timestamp"
                        />
                    </template>

                    <template v-else-if="column === 'last_gps_measurement'">
                        <time-ago
                            v-if="
                                tracker.last_gps_measurement &&
                                    tracker.last_gps_measurement.timestamp
                            "
                            :from-datetime="
                                tracker.last_gps_measurement.timestamp
                            "
                        />
                    </template>

                    <span v-else :title="getDynamicColumnDate(column, tracker)">
                        {{ getDynamicColumnValue(column, tracker) }}
                    </span>
                </td>

                <td v-if="dynamicColumnOptions.length" />
            </tr>
        </table>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import infiniteScroll from 'vue-infinite-scroll'
import VDraggable from 'vuedraggable'
import { get } from 'lodash'
import moment from 'moment-timezone'

import { domHelper, formatHelper, measurementHelper } from '@/utils'
import AssetAvatar from '@/components/AssetAvatar'
import BaseMultiselect from '@/components/redesigned/BaseMultiselect'
import FileDownloadIcon from '@/components/icons/FileDownloadIcon'
import IconButton from '@/components/IconButton'
import RemoveIcon from '@/components/icons/RemoveIcon'
import SortArrowIcon from '@/components/icons/SortArrowIcon'
import TimeAgo from '@/components/TimeAgo'

const defaultScrollLimit = 15
const defaultSortColumn = 'name'
const defaultSortDirection = 1
const wildcardPrefix = '$.'

export default {
    name: 'DashboardAssetsTableView',
    components: {
        AssetAvatar,
        BaseMultiselect,
        FileDownloadIcon,
        IconButton,
        RemoveIcon,
        SortArrowIcon,
        TimeAgo,
        VDraggable,
    },
    directives: {
        infiniteScroll,
    },
    props: {
        filterColor: {
            type: Array,
            default: () => [],
        },
        filterLocation: {
            type: Array,
            default: () => [],
        },
        filterSearch: {
            type: String,
            default: '',
        },
        filterType: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            dynamicColumnOptions: [
                'battery',
                'battery_status',
                'battery_voltage',
                'co2',
                'd1',
                'd2',
                'd3',
                'd4',
                'd5',
                'd6',
                'distance',
                'fill_level',
                'humidity',
                'identifier',
                'last_contact',
                'last_gps_measurement',
                'level',
                'location',
                'mass',
                'moisture',
                'power',
                'running_time',
                't1',
                't2',
                't3',
                't4',
                't5',
                't6',
                'temperature',
                'voc',
                'voltage_0',
                'voltage_1',
                'voltage_2',
                'voltage_3',
                'voltage_4',
                'volume',
            ],
            dynamicColumns: [],
            selected: [],
            scrollLimit: defaultScrollLimit,
            scrollStep: defaultScrollLimit,
            sortBy: defaultSortColumn,
            sortDirection: defaultSortDirection,
        }
    },
    computed: {
        ...mapState('dashboard', ['customColumns']),
        ...mapState('tracker', ['assetTypes', 'trackers']),
        ...mapGetters('authentication', ['isStaff']),
        dynamicColumnOptionsRemaining() {
            const groups = [
                {
                    group: this.$t('field'),
                    items: [],
                },
                {
                    group: this.$t('shared.measurements.state'),
                    items: [],
                },
                {
                    group: this.$t('shared.measurements.temperature'),
                    items: [],
                },
                {
                    group: this.$t('shared.measurements.voltage'),
                    items: [],
                },
            ]

            this.dynamicColumnOptions
                .filter(column => !this.dynamicColumns.includes(column))
                .forEach(column => {
                    if (/^d\d$/.test(column)) {
                        groups[1].items.push(column)
                    } else if (/^t\d$/.test(column)) {
                        groups[2].items.push(column)
                    } else if (/^voltage_\d$/.test(column)) {
                        groups[3].items.push(column)
                    } else {
                        groups[0].items.push(column)
                    }
                })

            return groups.map(group => ({
                ...group,
                items: group.items
                    .map(item => ({
                        key: item,
                        label: /^(d|t)\d$/.test(item)
                            ? `${this.$t('channel')} ${item[1]}`
                            : this.getDynamicColumnTitle(item),
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label)),
            }))
        },
        filteredTrackers() {
            let result = this.trackers

            if (this.filterSearch) {
                result = result.filter(
                    tracker =>
                        tracker.asset_details?.name
                            ?.toLowerCase()
                            .includes(this.filterSearch.toLowerCase()) ||
                        tracker.deveui
                            ?.toLowerCase()
                            .includes(this.filterSearch.toLowerCase()) ||
                        tracker.asset_details?.identification
                            ?.toLowerCase()
                            .includes(this.filterSearch.toLowerCase())
                )
            }

            if (this.filterColor.length) {
                result = result.filter(tracker =>
                    this.filterColor.includes(tracker.asset_details.color)
                )
            }

            if (this.filterLocation.length) {
                result = result.filter(tracker =>
                    this.filterLocation.some(
                        location => location.id === tracker.location
                    )
                )
            }

            if (this.filterType.length) {
                result = result.filter(tracker =>
                    this.filterType.some(
                        type => type.id === tracker.asset_details.asset_type
                    )
                )
            }

            if (this.sortBy) {
                const propertyGetterMap = {
                    deveui: tracker => tracker.deveui,
                    identifier: tracker => tracker.identifier,
                    last_contact: tracker => tracker.last_message_timestamp,
                    last_gps_measurement: tracker =>
                        tracker.last_gps_measurement?.timestamp,
                    location: tracker => tracker.location_details?.name,
                    measurement: tracker =>
                        tracker.asset_details.sensor_data[this.sortBy]?.value,
                    name: tracker => tracker.asset_details.name,
                    type: tracker => this.getTrackerTypeTranslated(tracker),
                    wildcard: tracker => {
                        const value = get(tracker, this.sortBy.slice(2))
                        return value && !isNaN(Number(value))
                            ? Number(value)
                            : value
                    },
                }

                const propertyGetter = this.sortBy.startsWith(wildcardPrefix)
                    ? propertyGetterMap.wildcard
                    : propertyGetterMap[this.sortBy] ||
                      propertyGetterMap['measurement']

                result = [...result].sort((a, b) => {
                    const aValue = propertyGetter(a)
                    const bValue = propertyGetter(b)

                    if (aValue === bValue) {
                        return 0
                    } else if (aValue == null) {
                        return 1
                    } else if (bValue == null) {
                        return -1
                    } else if (typeof aValue === 'string') {
                        return aValue.localeCompare(bValue) * this.sortDirection
                    }

                    return aValue > bValue
                        ? this.sortDirection
                        : -this.sortDirection
                })
            }

            return result
        },
        trackersPortion() {
            return this.filteredTrackers.length === this.trackers.length
                ? this.filteredTrackers.slice(0, this.scrollLimit)
                : this.filteredTrackers
        },
        isClearFilterVisible() {
            return (
                this.filterColor.length ||
                this.filterLocation.length ||
                this.filterSearch ||
                this.filterType.length
            )
        },
    },
    watch: {
        $route() {
            this.setDynamicColumnsFromRouteQuery()
        },
        dynamicColumns(value) {
            this.updateRoute(value)
        },
        selected(value) {
            this.$emit('update:selected', value)
        },
    },
    mounted() {
        if (this.isStaff) {
            this.dynamicColumnOptions = [
                ...this.dynamicColumnOptions,
                'deveui',
                'lower_loop',
                'potential',
                'probe_state',
                'tau',
                'upper_loop',
                'water_level',
            ]
        }

        if (this.customColumns) {
            this.updateRoute(this.customColumns)
        } else if (this.$route.query.column) {
            this.setDynamicColumnsFromRouteQuery()
        } else {
            this.dynamicColumns = ['location', 'identifier']
        }
    },
    methods: {
        ...mapMutations('dashboard', ['setCustomColumns']),
        getDynamicColumnTitle(column) {
            return this.$te(`columns.${column}`)
                ? this.$t(`columns.${column}`)
                : this.$root.$te(`shared.measurements.${column}`)
                ? this.$t(`shared.measurements.${column}`)
                : column
        },
        getDynamicColumnValue(column, tracker) {
            switch (column) {
                case 'deveui':
                    return tracker.deveui
                case 'identifier':
                    return tracker.identifier
                case 'location':
                    return tracker.location_details?.name
            }

            if (column.startsWith(wildcardPrefix)) {
                return get(tracker, column.slice(2))
            }

            let value = tracker.asset_details.sensor_data[column]?.value

            if (typeof value === 'boolean') {
                return this.$t(value ? 'on' : 'off')
            } else if (typeof value !== 'number') {
                return value
            }

            switch (column) {
                case 'fill_level':
                    value = parseInt(value * 100)
                    break
                case 'running_time':
                    return formatHelper.hoursAndMinutesDuration(value)
                case 'mass':
                case 'voltage_0':
                case 'voltage_1':
                case 'voltage_2':
                case 'voltage_3':
                case 'voltage_4':
                    value = (value / 1000).toFixed(2)
                    break
            }

            if (measurementHelper.units[column]) {
                value = `${value} ${measurementHelper.units[column]}`
            }

            return value
        },
        getDynamicColumnDate(column, tracker) {
            const lastUpdate =
                tracker.asset_details.sensor_data[column]?.last_update

            return lastUpdate
                ? moment(lastUpdate).format('DD.MM.YYYY HH:mm')
                : null
        },
        getTrackerTypeTranslated(tracker) {
            const translationKey = `shared.types.${tracker.asset_details.asset_type_identifier}`

            return this.$root.$te(translationKey)
                ? this.$t(translationKey)
                : this.assetTypes.find(
                      type => type.id === tracker.asset_details.asset_type
                  )?.name
        },
        handleColumnAdd(column) {
            const columns = [].concat(column)
            columns.forEach(item => {
                const key = typeof item === 'string' ? item : item.key
                if (!this.dynamicColumns.includes(key)) {
                    this.dynamicColumns.push(key)
                }
            })
        },
        handleColumnRemove(column) {
            if (this.sortBy === column) {
                this.sortBy = defaultSortColumn
                this.sortDirection = defaultSortDirection
            }

            this.dynamicColumns = this.dynamicColumns.filter(
                item => item !== column
            )
        },
        handleDownload() {
            const rows = [
                [
                    this.$t('columns.name'),
                    this.$t('columns.type'),
                    ...this.dynamicColumns.map(this.getDynamicColumnTitle),
                ],
                ...this.filteredTrackers.map(tracker => [
                    tracker.asset_details.name,
                    this.getTrackerTypeTranslated(tracker),
                    ...this.dynamicColumns.map(column =>
                        this.getDynamicColumnValue(column, tracker)
                    ),
                ]),
            ]

            const dataUrl = `data:text/csv;charset=utf-8,${rows
                .map(row => row.join(','))
                .join('\n')}`

            const filename = `${this.$t('shared.assets')}-${new Date()
                .toJSON()
                .slice(0, 19)
                .replace('T', '-')
                .replaceAll(':', '')}.csv`

            domHelper.downloadDataUrl(dataUrl, filename)
        },
        handleSort(column) {
            if (this.sortBy !== column || this.sortDirection !== 1) {
                this.sortBy = column
                this.sortDirection = 1
            } else if (this.sortDirection === 1) {
                this.sortDirection = -1
            }
        },
        loadMoreTrackers() {
            if (this.scrollLimit < this.trackers.length) {
                this.scrollLimit += this.scrollStep
            }
        },
        selectAll(event) {
            this.selected = event.target.checked
                ? this.filteredTrackers.map(tracker => tracker.asset)
                : []
        },
        setDynamicColumnsFromRouteQuery() {
            if (!this.$route.query.column?.length) {
                this.setCustomColumns(null)
                return
            }

            this.dynamicColumns =
                typeof this.$route.query.column === 'string'
                    ? [this.$route.query.column]
                    : this.$route.query.column.filter(
                          (column, i, columns) => columns.indexOf(column) === i
                      )

            this.setCustomColumns(this.dynamicColumns)
        },
        updateRoute(columns) {
            this.$router.replace({ query: { column: columns } })
        },
    },
}
</script>

<i18n>
{
    "en": {
        "columns": {
            "deveui": "DevEUI",
            "icon": "Farbe",
            "identifier": "Identifier",
            "last_contact": "Last contact",
            "last_gps_measurement": "Last GPS measurement",
            "location": "Location",
            "name": "Name",
            "type": "Type"
        },
        "addColumnPlaceholder": "Additional column",
        "channel": "Channel",
        "clearFilter": "Clear filter",
        "columnGroupState": "State",
        "columnGroupTemperature": "Temperature",
        "field": "Field",
        "off": "Off",
        "on": "On",
        "showOf": "Show {0} of {1}",
        "tooltipDownload": "CSV Export"
    },
    "de": {
        "columns": {
            "deveui": "DevEUI",
            "icon": "Farbe",
            "identifier": "Identifikation",
            "last_contact": "Letzter Kontakt",
            "last_gps_measurement": "Letzte GPS Messung",
            "location": "Standort",
            "name": "Name",
            "type": "Typ"
        },
        "addColumnPlaceholder": "Zusätzliche Spalte",
        "channel": "Kanal",
        "clearFilter": "Filter zurücksetzen",
        "columnGroupState": "Zustand",
        "columnGroupTemperature": "Temperatur",
        "field": "Feld",
        "off": "Aus",
        "on": "Ein",
        "showOf": "Zeige {0} von {1}",
        "tooltipDownload": "CSV Export"
    },
    "it": {
        "columns": {
            "deveui": "DevEUI",
            "icon": "Farbe",
            "identifier": "Identifier",
            "last_contact": "Ultimo Contatto",
            "last_gps_measurement": "Ultimo Misurazione del GPS",
            "location": "Locazione",
            "name": "Name",
            "type": "Type"
        },
        "addColumnPlaceholder": "Colonna aggiuntiva",
        "channel": "Canale",
        "clearFilter": "Annullare il filtro",
        "columnGroupState": "Stato",
        "columnGroupTemperature": "Temperatura",
        "field": "Campo",
        "off": "Off",
        "on": "On",
        "showOf": "Listo {0} di {1}",
        "tooltipDownload": "CSV Export"
    }
}
</i18n>

<style lang="scss" scoped>
.filter-actions {
    position: sticky;
    top: 0;
    left: 0;
    padding: 1rem 2rem;
    height: 52px;
    background-color: #fff;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
    z-index: 1;

    a {
        font-size: 13px;
        color: #000;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            text-decoration: none;
        }
    }

    button {
        position: absolute;
        right: 2rem;
        color: #000;
    }
}

.checkbox-cell {
    height: 100%;
    padding: 0;

    input,
    label {
        cursor: pointer;
    }

    label {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0.5rem 1rem 0.5rem 2rem;
    }
}

table {
    display: block;
    width: 100%;
    border-spacing: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);

    thead {
        position: sticky;
        top: 52px;
        background-color: #fff;
        z-index: 1;

        .icon-button {
            margin-left: 5px;
        }
    }

    tr {
        height: 100%;

        &:not(:first-child) {
            cursor: pointer;

            &:hover {
                background-color: $color-gray-lighter;
            }

            & + tr {
                td {
                    border-top: $style-border;
                }
            }
        }

        th {
            padding: 1rem;
            border-top: $style-border;
            border-bottom: $style-border;
            text-align: left;

            &.action {
                padding-top: 0;
                padding-bottom: 0;
                font-weight: normal;

                & > * {
                    width: 240px;
                }
            }

            &.dynamic {
                cursor: grab;
                transition: box-shadow 0.1s;

                &:active {
                    cursor: grabbing;
                }

                &:hover {
                    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
                }

                &.sortable-chosen,
                &.sortable-ghost {
                    background-color: #fff;
                    box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
                }
            }
        }

        td {
            padding: 0.5rem 1rem;
        }

        th,
        td {
            white-space: nowrap;

            &:not(:last-child) {
                width: 1%;
            }
        }
    }
}
</style>
