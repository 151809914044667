<template>
    <div
        class="dashboard-assets-view"
        :class="{
            'dashboard-assets-view--splitted': selected.length,
            'dashboard-assets-view--mobile-active': isEditing,
        }"
    >
        <div class="dashboard-assets-view__filters">
            <base-input
                v-model="filterSearch"
                :debounce="400"
                :placeholder="$t('filterByQuery')"
            >
                <template #icon>
                    <search-icon width="16" height="16" />
                </template>
            </base-input>

            <base-multiselect
                v-model="filterType"
                :placeholder="$t('filterByType')"
                :options="assetTypes"
                track-by="id"
                label="name"
                multiple
                block
            />

            <base-multiselect
                v-model="filterLocation"
                :placeholder="$t('filterByLocation')"
                :options="getLocationsSortedByName"
                track-by="id"
                label="name"
                multiple
                block
            />

            <color-input v-model="filterColor" class="redesigned" />
        </div>

        <div class="dashboard-assets-view__split">
            <dashboard-assets-table-view
                class="dashboard-assets-view__table"
                :selected.sync="selected"
                :filter-color="filterColor"
                :filter-location="filterLocation"
                :filter-search="filterSearch"
                :filter-type="filterType"
                @clearFilter="clearFilter"
                @click="isEditing = true"
            />

            <template v-if="selected.length">
                <span
                    :class="[
                        'dashboard-assets-view__split-icon',
                        {
                            ['dashboard-assets-view__split-icon--offset']: !isEditing,
                        },
                    ]"
                >
                    <icon-button @click="isEditing = !isEditing">
                        <remove-icon v-if="isEditing" width="18" height="18" />
                        <pencil-icon v-else width="18" height="18" />
                    </icon-button>
                </span>

                <dashboard-assets-edit-view
                    class="dashboard-assets-view__form"
                    :asset-ids="selected"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import BaseInput from '../redesigned/BaseInput'
import BaseMultiselect from '../redesigned/BaseMultiselect'
import ColorInput from '../ColorInput'
import DashboardAssetsEditView from './DashboardAssetsEditView'
import DashboardAssetsTableView from './DashboardAssetsTableView'
import IconButton from '../IconButton'
import PencilIcon from '../icons/PencilIcon'
import RemoveIcon from '../icons/RemoveIcon'
import SearchIcon from '../icons/SearchIcon'

export default {
    name: 'DashboardAssetsView',
    components: {
        BaseInput,
        BaseMultiselect,
        ColorInput,
        DashboardAssetsEditView,
        DashboardAssetsTableView,
        IconButton,
        PencilIcon,
        RemoveIcon,
        SearchIcon,
    },
    data() {
        return {
            selected: [],
            filterColor: [],
            filterLocation: [],
            filterSearch: '',
            filterType: [],
            isEditing: false,
        }
    },
    computed: {
        ...mapState('tracker', ['assetTypes']),
        ...mapGetters('locations', ['getLocationsSortedByName']),
    },
    methods: {
        clearFilter() {
            this.filterColor = []
            this.filterLocation = []
            this.filterSearch = ''
            this.filterType = []
        },
    },
}
</script>

<i18n>
{
    "en": {
        "filterByColor": "Color",
        "filterByLocation": "Location",
        "filterByQuery": "Name",
        "filterByType": "Type"
    },
    "de": {
        "filterByColor": "Farbe",
        "filterByLocation": "Standort",
        "filterByQuery": "Name",
        "filterByType": "Typ"
    },
    "it": {
        "filterByColor": "Colore",
        "filterByLocation": "Locazione",
        "filterByQuery": "Name",
        "filterByType": "Typo"
    }
}
</i18n>

<style lang="scss" scoped>
.dashboard-assets-view {
    $self: &;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &__filters {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 1.5rem 1rem 0.5rem 2rem;
        background-color: $color-gray-lighter-new;
        border-bottom: $style-border;

        & > * {
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        .base-input,
        .multiselect {
            max-width: 200px;
        }
    }

    &__split {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        overflow-y: auto;

        & > div {
            width: 375px;
            height: 100%;
            overflow-y: auto;
        }

        #{$self}__table {
            width: 100%;
        }
    }

    &__split-icon {
        display: none;
        position: absolute;
        padding: 1rem;
        top: 0;
        right: 0;
        margin: 0 0.5rem;
        z-index: 2;

        &--offset {
            right: 4rem;
            margin: 0;
        }
    }

    &--splitted {
        #{$self}__table {
            width: calc(100% - 375px);
        }

        #{$self}__form {
            border-left: $style-border;
        }
    }

    @include respond-to('for-mobile-down') {
        &__split {
            flex-direction: column-reverse;

            & > div {
                width: 100% !important;
                height: auto;
                border-left: none !important;
                flex: 1 0 100%;
            }
        }

        &__split-icon {
            display: inline-block;
        }

        &--splitted {
            #{$self}__form {
                display: none;
            }

            &#{$self}--mobile-active {
                #{$self}__filters {
                    display: none;
                }

                #{$self}__split {
                    height: auto;
                    overflow-y: initial;
                }

                #{$self}__table {
                    display: none;
                }

                #{$self}__form {
                    display: block;
                }
            }
        }
    }

    @include respond-to('for-small-mobile-only') {
        &__filters {
            padding: 1rem 1rem 0;

            & > * {
                margin-right: 0;
            }

            .base-input,
            .multiselect {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}
</style>
