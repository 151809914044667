var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-assets-view",class:{
        'dashboard-assets-view--splitted': _vm.selected.length,
        'dashboard-assets-view--mobile-active': _vm.isEditing,
    }},[_c('div',{staticClass:"dashboard-assets-view__filters"},[_c('base-input',{attrs:{"debounce":400,"placeholder":_vm.$t('filterByQuery')},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('search-icon',{attrs:{"width":"16","height":"16"}})]},proxy:true}]),model:{value:(_vm.filterSearch),callback:function ($$v) {_vm.filterSearch=$$v},expression:"filterSearch"}}),_c('base-multiselect',{attrs:{"placeholder":_vm.$t('filterByType'),"options":_vm.assetTypes,"track-by":"id","label":"name","multiple":"","block":""},model:{value:(_vm.filterType),callback:function ($$v) {_vm.filterType=$$v},expression:"filterType"}}),_c('base-multiselect',{attrs:{"placeholder":_vm.$t('filterByLocation'),"options":_vm.getLocationsSortedByName,"track-by":"id","label":"name","multiple":"","block":""},model:{value:(_vm.filterLocation),callback:function ($$v) {_vm.filterLocation=$$v},expression:"filterLocation"}}),_c('color-input',{staticClass:"redesigned",model:{value:(_vm.filterColor),callback:function ($$v) {_vm.filterColor=$$v},expression:"filterColor"}})],1),_c('div',{staticClass:"dashboard-assets-view__split"},[_c('dashboard-assets-table-view',{staticClass:"dashboard-assets-view__table",attrs:{"selected":_vm.selected,"filter-color":_vm.filterColor,"filter-location":_vm.filterLocation,"filter-search":_vm.filterSearch,"filter-type":_vm.filterType},on:{"update:selected":function($event){_vm.selected=$event},"clearFilter":_vm.clearFilter,"click":function($event){_vm.isEditing = true}}}),(_vm.selected.length)?[_c('span',{class:[
                    'dashboard-assets-view__split-icon',
                    ( _obj = {}, _obj['dashboard-assets-view__split-icon--offset'] = !_vm.isEditing, _obj ) ]},[_c('icon-button',{on:{"click":function($event){_vm.isEditing = !_vm.isEditing}}},[(_vm.isEditing)?_c('remove-icon',{attrs:{"width":"18","height":"18"}}):_c('pencil-icon',{attrs:{"width":"18","height":"18"}})],1)],1),_c('dashboard-assets-edit-view',{staticClass:"dashboard-assets-view__form",attrs:{"asset-ids":_vm.selected}})]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }